@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

$scan-width: 2px;
$scan-crt: true;
$scan-fps: 60;
$scan-color: rgba(#000, .3);
$scan-z-index: 2147483648;
$scan-moving-line: true;
$scan-opacity: .5;

@mixin scan-crt($scan-crt) {
  @if $scan-crt == true {
      animation: scanlines 1s steps($scan-fps) infinite;
  }
  @else { animation: none; }
}

@mixin scan-moving($scan-moving-line) {
  @if $scan-moving-line == true {
      animation: scanline 6s linear infinite;
  }
  @else { animation: none; }
}

.aberration {
  filter: drop-shadow(1px 0 2px rgba(#14FF00,1)) drop-shadow(-1px 0 2px rgba(#FF0000,1));
}

main {
  position: relative;
  display: grid;
  grid-template-rows: 1fr;
  align-items: center;
  height: 100vh;
  color: #fff;
  background-color: #030C13;
  overflow: hidden;
  &:before,
  &:after {
    display: block;
    pointer-events: none;
    content: '';
    position: absolute;
  }
  &:before {
    // position: absolute;
    // bottom: 100%;
    width: 100%;
    height: $scan-width * 1;
    z-index: $scan-z-index + 1;
    background: $scan-color;
    opacity: $scan-opacity;
    // animation: scanline 6s linear infinite;
    @include scan-moving($scan-moving-line);
  }
  &:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $scan-z-index;
    background: linear-gradient(
                to bottom,
                transparent 50%,
                $scan-color 51%
            );
    background-size: 100% $scan-width*2;
    @include scan-crt($scan-crt);
  }
}

.c-header {
  position: fixed;
  top: 2vh;
  left: 50%;
  transform: translateX(-50%);
  img {
    height: 26vh;
    filter: blur(1px) drop-shadow(0 0 12px rgba(#FF00C0,0.9));
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 5%;
    left: 5%;
    display: block;
    height: 60%;
    border-radius: 50%;
    background-color: #030C13;
    filter: blur(20px);
    opacity: 0.72;
    transform: translateY(50%);
    z-index: 12;
  }
}

.c-footer-background {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  height: 40%;
  background: linear-gradient(180deg, #000000 2.27%, rgba(0, 0, 0, 0) 100%), linear-gradient(360deg, rgba(0, 0, 0, 0.79) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(180deg, #FF00C0 2.27%, #0094FF 100%);
  background-blend-mode: normal, normal, color, color-dodge;
  mix-blend-mode: screen;
  filter: blur(2px);
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 5%;
    left: 5%;
    display: block;
    height: 60%;
    border-radius: 50%;
    background-color: #030C13;
    filter: blur(20px);
    opacity: 0.72;
    transform: translateY(-50%);
    z-index: 12;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: block;
    height: 4px;
    background-color: #fff;
    box-shadow: inset 0 0 1px #FF00C0, 0 0 24px #FF00C0;
  }
}

.c-footer-background__start {
  position: absolute;
  bottom: 56px;
  left: 50%;
  font-size: 32px;
  font-family: 'Press Start 2P';
  text-shadow: 1px 0 1px rgba(#14FF00,1), -1px 0 1px rgba(#FF0000,1), 0 8px 0 #000;
  text-transform: uppercase;
  transform: translateX(-50%);
  animation: flash 1s infinite steps(2) both;
  opacity: 1;
}

@keyframes flash {
  100% {
    opacity: 0;
  }
}

.c-core {
  padding: 0 6%;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 56px;
}

.c-table {
  position: relative;
  padding: 64px 0 40px;
  border: 5px solid #E8A3D7;
  border-radius: 24px;
  background: rgba(#000,0);
  font-size: 1vw;
  @extend .aberration;
  text-shadow: 1px 0 1px rgba(#14FF00,1), -1px 0 1px rgba(#FF0000,1);
  box-shadow: 0 0 24px rgba(#fff,0.2);
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    // background: rgba(#000,0.72);
    z-index: -1;
  }
}

.c-table__title {
  position: absolute;
  top: 0;
  left: 50%;
  display: flex;
  justify-content: center;
  width: 70%;
  padding: 24px;
  border: 5px solid #E8A3D7;
  border-radius: 100px;
  background-color: #030C13;
  box-shadow: 0 0 24px rgba(#fff,0.2);
  transform: translate(-50%,-50%);
  // filter: blur(1px);
  img {
    filter: drop-shadow(0px 0px 8px rgba(#fff, 0.7)) blur(0.8px);
  }
}

.c-table__body {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-x: hidden;
  scrollbar-width: 0;
  > *:first-child {
    > div {
      color: #fff;
      text-shadow:
      0 0 2px rgba(#fff,0.4),
      0 0 4px rgba(#fff,0.4),
      0 0 7px rgba(#fff,0.4),
      0 0 16px rgba(#FF00C0,0.25),
      0 0 24px rgba(#FF00C0,0.2),
      0 0 32px rgba(#FF00C0,0.15),
      0 0 40px rgba(#FF00C0,0.1),
      0 0 48px rgba(#FF00C0,0.05);
      animation: winFlash 0.1s infinite both linear;
    }
  }
}

@keyframes winFlash {
  100% {
    text-shadow:
      0 0 2px rgba(#fff,0.2),
      0 0 4px rgba(#fff,0.2),
      0 0 7px rgba(#fff,0.2),
      0 0 16px rgba(#FF00C0,0.1),
      0 0 24px rgba(#FF00C0,0.1),
      0 0 32px rgba(#FF00C0,0.1),
      0 0 40px rgba(#FF00C0,0.1),
      0 0 48px rgba(#FF00C0,0.1);
  }
}

.c-table__head,
.c-table__row {
  padding: 8px 40px;
  display: grid;
  grid-template-columns: 80px 1fr 1fr;
  gap: 48px;
  font-family: 'Press Start 2P';
  text-shadow:
    0 0 2px #fff;
  filter: drop-shadow(0px 4px 4px rgba(#E8A3D7, 0.2));
}

.c-table__head {
  margin-bottom: 24px;
}

.c-table__row {
  animation: grow 8s infinite linear;
  &:nth-child(1) { animation-delay: 0.5s; }
  &:nth-child(2) { animation-delay: 0.6s; }
  &:nth-child(3) { animation-delay: 0.7s; }
  &:nth-child(4) { animation-delay: 0.8s; }
  &:nth-child(5) { animation-delay: 0.9s; }
  &:nth-child(6) { animation-delay: 1.0s; }
  &:nth-child(7) { animation-delay: 1.1s; }
  &:nth-child(8) { animation-delay: 1.2s; }
  &:nth-child(9) { animation-delay: 1.3s; }
}

@keyframes grow {
  0% {
    transform: scale(1);
  }
  89% {
    transform: scale(1);
    background-color: transparent;
  }
  90% {
    transform: scale(1.05);
    background-color: rgba(#fff,0.1);
  }
  93% {
    transform: scale(1.05);
    background-color: rgba(#fff,0.1);
  }
  94% {
    transform: scale(1);
    background-color: transparent;
  }
}

@keyframes scanline {
  0% {
      transform: translate3d(0,200000%,0);
      // bottom: 0%; // to have a continuous scanline move, use this line (here in 0% step) instead of transform and write, in &:before, { position: absolute; bottom: 100%; }
  }
}

@keyframes scanlines {
  0% {
      background-position: 0 50%;
      // bottom: 0%; // to have a continuous scanline move, use this line (here in 0% step) instead of transform and write, in &:before, { position: absolute; bottom: 100%; }
  }
}

.c-footer-nav__anim-overlay {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  // background-color: #0e1416;
  // grid-column: 1 / -1;
}

.c-footer-nav__anim-grid {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 10;
  perspective: 450px;
}

.c-footer-nav__anim-fade {
  visibility: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 11;
  background: radial-gradient(ellipse at 50% 50%, rgba(14, 20, 22, 0) 0%, #0e1416 80%);
}

.c-footer-nav__anim-lines {
  position: relative;
  width: 100%;
  height: 200%;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.3) 1px, transparent 0), linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 1px, transparent 0);
  background-size: 45px 35px;
  background-repeat: repeat;
  transform-origin: 100% 0 0;
  animation: play 15s linear infinite;
}

@keyframes play {
  0% {
    transform: rotateX(45deg) translateY(-50%);
  }
  100% {
    transform: rotateX(45deg) translateY(0);
  }
}